<template>
  <div class="big-title">
    <div class="lbg"></div>
    <div class="center-tit">
      <h2><slot name="title1"></slot></h2>
      <p><slot name="title2"></slot></p>
    </div>
    <div class="rbg"></div>
  </div>
</template>
<style lang="less" scoped>
.big-title{
  display: flex;
  justify-content: space-between;
  margin-bottom: 36px;
  .lbg{
    background-image: url(../assets/img/left.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 80px;
    height: 40px;
  }
  .rbg{
    background-image: url(../assets/img/right.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 80px;
    height: 40px;
  }
  .center-tit{
    color: #333E55;
    h2{
      font-weight: bold;
      font-size: 30px;
    }
    p{
      font-weight: bold;
      font-size: 16px;
    }
  }
}
</style>