<template>
  <Title>
    <template v-slot:title1>联系我们</template>
    <template v-slot:title2>Join US</template>
  </Title>
  <div ref="mapRef" class="mapRef"></div> 
  <!-- <img class="a-img-box" src="../assets/img/contact.jpg" alt=""> -->
  <div class="address-box">
    <p>地址：浙江省杭州市余杭区星运数字创造中心5幢1302室</p>
    <p>微信：mituo456321 冯先生</p>
    <p>QQ：596923143  黄先生</p>
    <p>邮箱：huangrunbin_accp@163.com</p>
  </div>
</template>
<script setup>
import Title from '../components/title.vue'
import {ref,onMounted} from 'vue'
const mapRef = ref();
onMounted(()=>{
  const map = new BMapGL.Map(mapRef.value);          // 创建地图实例 
  const point = new BMapGL.Point(120.06375 , 30.36940);  // 创建点坐标 
  map.centerAndZoom(point, 15);                 // 初始化地图，设置中心点坐标和地图级别
  const marker = new BMapGL.Marker(point);        // 创建标注   
  map.addOverlay(marker)
})
</script>
<style lang="less" scoped>
.mapRef{
  width: 100%;
  height: 400px;
}
.address-box{
  font-size: 24px;
  line-height: 36px;
  margin: 30px 0;
}
</style>